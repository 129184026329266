import {Redirect, Route, Switch} from "react-router";
import {lazy, Suspense} from 'react';

import Layout from "./hoc/Layout/Layout";

import Home from "./pages/Home";
import page404 from "./pages/404";
import {useEffect, useState} from "react";
import Loader from "./components/Loader/Loader";

const News = lazy(() => import('./containers/News/News'));
const Reviews = lazy(() => import('./containers/Reviews/Reviews'));
const Promotions = lazy(() => import('./containers/Promotions/Promotions'));
const PromotionsFinished = lazy(() => import('./containers/Promotions/PromotionsFinished'));
const About = lazy(() => import('./pages/About'));
const OsnovatelFonda = lazy(() => import('./pages/OsnovatelFonda'));
const DirectorFonda = lazy(() => import('./pages/DirectorFonda'));
const Sovet = lazy(() => import('./pages/Sovet'));
const NeedHelp = lazy(() => import('./pages/NeedHelp'));
const Contact = lazy(() => import('./pages/Contact'));
const HelpMan = lazy(() => import("./pages/HelpMan"));
const Help1 = lazy(() => import("./pages/Help_1"));
// const Help2 = lazy(() => import("./pages/Help_2"));
const Help3 = lazy(() => import("./pages/Help_3"));
const Help4 = lazy(() => import("./pages/Help_4"));
const Help5 = lazy(() => import("./pages/Help_5"));
const Help6 = lazy(() => import("./pages/Help_6"));
const Help7 = lazy(() => import("./pages/Help_7"));
const ReportsIn = lazy(() => import("./pages/ReportsIn"));
const ReportsOut = lazy(() => import("./pages/ReportsOut"));
const ReportsInMonth = lazy(() => import("./containers/Reports/ReportsIn/ReportsInMonth"));
const ReportsOutMonth = lazy(() => import("./containers/Reports/ReportsOut/ReportsOutMonth"));
const ReportsToMinistry = lazy(() => import("./pages/ReportsToMinistry"));
const Gratitude = lazy(() => import("./pages/Gratitude"));
const GratitudeInfo = lazy(() => import("./containers/Gratitude/GratitudeInfo"));
const Profile = lazy(() => import("./containers/Profile/Profile"));
const LongNews = lazy(() => import("./components/News/LongNews"));

function App() {
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWidth = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', updateWidth)
        return () => {
            window.removeEventListener('resize', updateWidth)
        }
    }, [])

    const handlePopupClick = () => {
        setIsPopupOpened(true)
    }

    const handleMobileMenuClick = () => {
        setIsMobileMenuOpened(true)
    }

    const closeAllPopups = () => {
        setIsPopupOpened(false)
        setIsMobileMenuOpened(false)
    }
    return (
        <Layout handleMobileOpen={handleMobileMenuClick} isMobileOpen={isMobileMenuOpened} windowWidth={windowWidth}
                isOpened={isPopupOpened} onClose={closeAllPopups} handlePopupClick={handlePopupClick}>
            <Suspense fallback={<Loader/>}>
                <Switch>
                    <Route path="/" exact component={Home}/>

                    <Route path="/news" exact component={News}/>

                    <Route path="/reviews" exact component={Reviews}/>

                    <Route path="/they-need-help" exact component={Promotions}/>
                    <Route path="/we-helped" exact component={PromotionsFinished}/>

                    <Route path="/pages/about" component={About}/>
                    <Route path="/pages/osnfonda" component={OsnovatelFonda}/>
                    <Route path="/pages/dirfonda" component={DirectorFonda}/>
                    <Route path="/pages/sovet" component={Sovet}/>
                    <Route path="/pages/needhelp" component={NeedHelp}/>
                    <Route path="/pages/contact" component={Contact}/>
                    <Route path="/pages/helpman" component={HelpMan}/>
                    <Route path="/pages/to-help" component={Help1}/>
                    {/* <Route path="/pages/to-help" component={Help2}/> */}
                    <Route path="/pages/help_1" component={Help1}/>
                    <Route path="/pages/help_3" component={Help3}/>
                    <Route path="/pages/help_4" component={Help4}/>
                    <Route path="/pages/help_5" component={Help5}/>
                    <Route path="/pages/help_6" component={Help6}/>
                    <Route path="/pages/help_7" component={Help7}/>

                    <Route path="/reports/in" exact component={ReportsIn}/>
                    <Route path="/reports/in/:year" component={ReportsInMonth}/>
                    <Route path="/reports/out" exact component={ReportsOut}/>
                    <Route path="/reports/out/:year" component={ReportsOutMonth}/>
                    <Route path="/reports/to-ministry" exact component={ReportsToMinistry}/>

                    <Route path="/gratitude" exact component={Gratitude}/>
                    <Route path="/gratitude/:year" component={GratitudeInfo}/>

                    <Route path="/profile/:name" component={Profile}/>
                    <Route path="/news/:name" component={LongNews}/>

                    <Route path="/404" component={page404}/>
                    <Redirect to="/404"/>
                </Switch>
            </Suspense>
        </Layout>
    );
}

export default App;
